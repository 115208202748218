<c-modal-header>
  <h5 cModalTitle>Create Channel</h5>
  <button (click)="activateModal.close()" cButtonClose></button>
</c-modal-header>
<c-modal-body class="d-flex flex-column">
  <form [formGroup]="userForm">
    <c-row class="justify-content-center mt-2">
      <c-col md="6">
        <label>Channal Name</label>
        <input cFormControl formControlName="firstname" name="FirstName" type="text" (keyup)="onChannelNameChange()" />
      </c-col>
      <c-col md="6">
        <label>Unique Link</label>
        <input cFormControl name="Unique Link" formControlName="unique_link" type="text" />
      </c-col>
    </c-row>
    <c-row class="d-flex justify-content-center mt-4">
      <c-col class="d-flex flex-column">
        <label>Channel Profile</label>
        <div class="d-flex c-size-icon" *ngIf="!selectedFile">
          <span class="material-symbols-outlined cursor" (click)="uploadImage.click()">
            perm_media
          </span>
          <input #uploadImage class="d-none" type="file" id="fileInput" (change)="onFileSelected($event)"
            accept="image/*" formControlName="profile_pic_name" />
        </div>
        <ng-container *ngIf="selectedFile">
          <div class="position-relative w-100-px h-100-px">
            <span class="material-symbols-outlined position-absolute start-100 rounded-1 text-white bg-danger cursor"
              (click)="removePostSelectedFile()">
              close
            </span>
            <img loading="lazy" height="150" width="150" data-src="{{ selectedFile }}"
              class="w-100 h-100 rounded-4 mt-2" alt="icon" onerror="this.onerror=null;" />
          </div>
        </ng-container>
      </c-col>
      <c-col class="align-self-center">
        <label class="form-check-label" for="flexSwitchCheckChecked">Feature</label>
        <div class="form-check form-switch cursor">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
            formControlName="feature" />
        </div>
      </c-col>
      <!-- [multiple]="true" -->
      <c-col md="12" class="mt-3">
        <!-- <label for="">Channel Admin</label> -->
        <label for="">Master Admin</label>
        <ng-select [items]="userList" bindLabel="Username" bindValue="Id" placeholder="Select user"
          (search)="onItemSelect($event)">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div (click)="onSelectUser(item)">
              <img height="20" width="20" [src]="item.ProfilePicName" class="me-1"
                onerror="this.onerror=null;this.src='/assets/img/avatars/placeholder-user.png';" />
              <span>{{ item.Username }}</span>
            </div>
          </ng-template>
        </ng-select>
        <!-- <c-row class="text-danger ms-0">Note: Once set, the Master Admin cannot be changed or removed.</c-row> -->
        <c-row class="text-danger text-center mt-2">Note: Once set, the Master Admin cannot be changed or removed. You can assign admin or sub-admins later.</c-row>
      </c-col>
    </c-row>
  </form>
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" class="text-white" (click)="activateModal.close()">
    Cancel
  </button>
  <button cButton class="text-white" color="secondary" type="submit" (click)="upload()"
    [disabled]="!userForm.get('profileid')?.value">
    Save
  </button>
</c-modal-footer>